//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PublicHeader from "../Header";
import PublicFooter from "../Footer";
import PlanCards from "./PlanCards";
import PeriodSelector from "./PeriodSelector";
import Review from "./Review";
//import WarningPrice from "@/components/Alert/WarningPrice.vue";
//import Clients from "./Clients";

import { aMONTH_DISCOUNT } from "../../../../common/monthDiscount";
//require('../../../../common/monthDiscount.js')

console.log("Plans.vue: aMONTH_DISCOUNT=", aMONTH_DISCOUNT);
//console.log('Plans.vue: aMONTH_DISCOUNT_PERIOD=', aMONTH_DISCOUNT_PERIOD)

export default {
  meta: {
    title: "关税和价格",
    meta: [{
      name: "description",
      content: "SalesFinder - 搜索有利可图的利基市场, 分析竞争对手的价格和销售额, 跟踪位置, 品牌和卖家的数据. 方便的市场分析可增加您的销售额!"
    }, {
      name: "viewport",
      content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
    }]
  },
  data() {
    const periods = [{
      name: "1个月",
      months: 1
    },
    //&: BLACK_FRIDAY-PROMO: 2023-11-14
    {
      name: "3个月",
      months: aMONTH_DISCOUNT[0].quantity,
      discount: aMONTH_DISCOUNT[0].percent
    }, {
      name: "6个月",
      //months: 6, discount: 20
      months: aMONTH_DISCOUNT[1].quantity,
      discount: aMONTH_DISCOUNT[1].percent
    }, {
      name: "12个月",
      //months: 12, discount: 30
      months: aMONTH_DISCOUNT[2].quantity,
      discount: aMONTH_DISCOUNT[2].percent
    }];
    console.log("periods=", periods);
    return {
      periods,
      activePeriod: periods[0]
    };
  },
  components: {
    PublicHeader,
    PlanCards,
    PublicFooter,
    PeriodSelector,
    Review
    // WarningPrice,
    // Clients,
  }
};