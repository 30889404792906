//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import BillingMixin from "@/components/Billing/Billing.mixin";
import PaymentForm from "./PaymentForm.vue";
export default {
  mixins: [ModalMixin, BillingMixin],
  data() {
    return {
      formProps: null,
      formState: {}
    };
  },
  mounted() {},
  methods: {
    onOpen(props) {
      this.formProps = props;
    },
    onComplete() {
      this.$emit("complete");
      this.close();
    }
  },
  computed: {
    title() {
      var _this$formProps, _this$formProps2;
      const currentSubscriptions = this.$store.state.billing.currentSubscriptions;
      let planName = null;
      const hasSubscriptions = currentSubscriptions === null || currentSubscriptions === void 0 ? void 0 : currentSubscriptions.length;
      if (hasSubscriptions) {
        planName = currentSubscriptions[0].plan.name;
      }
      if (((_this$formProps = this.formProps) === null || _this$formProps === void 0 ? void 0 : _this$formProps.type) === "change") {
        return planName ? `更改${planName}套餐` : `关税变化`;
      }
      if (((_this$formProps2 = this.formProps) === null || _this$formProps2 === void 0 ? void 0 : _this$formProps2.type) === "prolong") {
        return planName ? `订阅付款 - ${planName}套餐` : `订阅付款`;
      }
      return "订阅付款";
    }
  },
  components: {
    Modal,
    PaymentForm
  }
};